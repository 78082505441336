@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/open-sans/OpenSans-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/open-sans/OpenSans-Bold.ttf") format("ttf");
}

body, html {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 13px;
  }
}

hr {
  border: 1px solid #E6E6E6;
  margin: 0;
}

* {
  box-sizing: border-box;
}
